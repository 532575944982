<template>
  <v-card elevation="1" class="py-4 px-6">
    <p class="text-uppercase caption font-weight-bold">Log de Notificações</p>
    <v-simple-table>
      <template v-slot:default>
        <thead class="grey lighten-4">
          <tr>
            <th class="text-left">Nome</th>
            <th v-if="type === 'Email'" class="text-left">E-mail</th>
            <th v-if="type === 'Pushnotification'" class="text-left">
              Código do App (push_token)
            </th>
            <th class="text-left">Status</th>
            <th class="text-left">Última Atualização</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in logNotifications" :key="item.id">
            <td>{{ item.employee.name }}</td>
            <td v-if="type === 'Email'">{{ item.employee.email }}</td>
            <td v-if="type === 'Pushnotification'">
              {{ item.employee.pushToken }}
            </td>
            <td>{{ item.status }}</td>
            <td>{{ formatarData(item.updatedAt) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "NotificacaoLogTabela",
  props: {
    logNotifications: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatarData(date) {
      const data = new Date(date);
      const dia = String(data.getDate()).padStart(2, "0");
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const ano = data.getFullYear();
      const horas = String(data.getHours()).padStart(2, "0");
      const minutos = String(data.getMinutes()).padStart(2, "0");
      return `${dia}/${mes}/${ano} - ${horas}:${minutos}`;
    },
  },
};
</script>
