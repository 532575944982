<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/notificacoes"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                depressed
                color="fmq_gray"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Ações <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="duplicar(item)">
                <v-list-item-title>Duplicar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="enviar()">
                <v-list-item-title>Enviar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <NotificacaoFormulario
          :item="item"
          :listFuncionarios="listFuncionarios"
          :listForcaDeVendas="listForcaDeVendas"
        />
        <NotificacaoLogTabela
          v-if="item.logNotifications.length > 0"
          class="mt-6"
          :logNotifications="item.logNotifications"
          :type="item.type"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Notificaçao foi enviada e encontrasse em processamento"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { showNotification, sendNotification } from "@/services/notification.js";
import NotificacaoFormulario from "@/components/notificacoes/NotificacaoFormulario.vue";
import NotificacaoLogTabela from "@/components/notificacoes/NotificacaoLogTabela.vue";
import { listEmployee } from "@/services/employee";
import { listSaleForce } from "@/services/sale-force";
export default {
  name: "NotificacaoVisualizar",
  components: { NotificacaoFormulario, NotificacaoLogTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Notificações",
        disabled: false,
        to: "/notificacoes",
      },
      {
        text: "Visualizar Notificação",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    item: {},
    messageError: null,
    loading: false,
    listFuncionarios: [],
    listForcaDeVendas: [],
    success: false,
  }),
  created() {
    this.listarFuncionarios();
    this.listarForcaDeVendas();
    this.exibir();
  },
  methods: {
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await showNotification(this.$route.params.id);
        this.item = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async enviar() {
      this.error = false;
      this.success = false;
      try {
        await sendNotification(this.$route.params.id).then(() => {
          this.success = true;
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async listarFuncionarios() {
      const resp = await listEmployee();
      this.listFuncionarios = resp.data;
    },
    async listarForcaDeVendas() {
      const resp = await listSaleForce();
      this.listForcaDeVendas = resp.data;
    },
    duplicar(item) {
      this.$store.dispatch("setDuplicateNotification", item).then(() => {
        this.$router.push({ name: "NotificacaoCriar" });
      });
    },
    goTo() {
      this.success = false;
      this.$router.push({ name: "Notificacoes" });
    },
  },
};
</script>

<style></style>
